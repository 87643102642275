/*
 * @Description: 
 * @author: lynn
 * @Date: 2023-04-03 18:25:10
 */
import React from 'react'

export default function Home() {

  return (
    <div className='p__home'>
      <div className='banner'>
        <div className='container'>
          <div className='flex u-justify-content-between pt7'>
            <div className='head_left'>
              <img src="/img/logo.png" alt="logo" />
            </div>
            <div className='head_right'>
              <img src="/img/weixin.svg" className='mr4-d' alt="weixin" />
              <img src="/img/weibo.svg" className='mr4-d' alt="weibo" />
              <img src="/img/lang.svg" alt="lang" />
            </div>
          </div>
          <div className='head_title'>
            <div className='f56 fw6 u-color-white'>
              窝窝投—一键全方位装修设计
            </div>
            <div className='f32 u-color-white mt3-d'>
              匠心筑梦 幸福安家
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='mt6-d f42 u-text-center'>
          关于窝窝投
        </div>
        <div className='mt6 intor_desc'>
          窝窝投（成都）网络科技信息有限公司，是您的品牌之花，在互联网的海洋中绽放。我们是一支充满创造力和激情的团队，致力于为客户提供最佳的网络装修服务。
        </div>
        <div className='intro'>
          <img alt="" src="/img/intro_img.jpg" />
          <div className='info'>
            <p>

              在我们的世界里，每一个像素都是一种艺术，每一条代码都是一种音符。我们的服务包括网站设计、网店建设、移动应用开发等，每一项服务都由我们精心打造，呈现出独特的美感和灵魂。
            </p>
            <p>

              我们不仅注重技术和创新，更注重与客户建立深厚的合作关系。我们倾听客户的需求和期望，将其转化为线上存在的完美表现。我们相信只有真正了解客户，才能做出最好的作品。
            </p>
            <p>

              选择窝窝投，就是选择与众不同的品牌体验。让我们用我们的创造力和才华，为您的品牌带来无限的可能性。让我们共同谱写属于您的品牌之歌！
            </p>
          </div>
        </div>

      </div>
      <div className='hr'></div>
      <div className='code flex'>
        <div className='code_l u-text-center'>
          <img alt="" src="/img/code.jpg" />
          <div className='f24 mt6'>
          扫描二维码下载APP  立即体验
          </div>
        </div>
        <div className='code_c'>

          <img alt="" src="/img/arrows.svg" />
        </div>
        <div className='code_r'>
          <img alt="" src="/img/phone.jpg" />
        </div>
      </div>
      <div className='footer'>
        Copyright © 2013-现在 窝窝投
        <a target="_blank" rel= "noreferrer" href='https://beian.miit.gov.cn/#/Integrated/index '>
        蜀ICP备2023015431号-1
        </a>
      </div>
    </div>
  )
}