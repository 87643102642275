/*
 * @Description: 
 * @author: lynn
 * @Date: 2023-04-03 18:06:47
 */
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import './assets/global.less';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
